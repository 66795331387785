import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Seo from '../components/layout/Seo';
import { IconArrowRight, IconMail2, IconPhone, IconPlace } from '../components/icons/Icons';
import Map from '../components/common/Map';

const CheckoutThankYou = () => {
  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo title="Thank you! Checkout Completed" />

      <section className="h-80 w-full bg-gradient-to-t from-cyan-500 to-blue-500">
        <div>THANK YOU!</div>
      </section>

      <section id="How to get a Watch Quote" className="flex justify-center">
        <div className="w-3/4">
          <h2 className="py-20 text-center text-4xl uppercase">What happens next?</h2>
          <div className={'w-full'}>
            <div className={'how-it-works flex flex-wrap items-center justify-center gap-4 px-4'}>
              <div className={'flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1'}>
                <StaticImage src={'../images/homepage/value.png'} alt={'Valuation'} />
                <h3>Get a Valuation</h3>
                <p>
                  Tell us about your jewelry with our simple form. The more information and photos
                  you share with us the more accurate the market valuation will be.
                </p>
              </div>
              <div className={'hidden flex-shrink self-center md:block'}>
                <IconArrowRight className={'text-accent'} />
              </div>
              <div className={'flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1'}>
                <StaticImage src={'../images/homepage/shipping.png'} alt={'Shipping'} />
                <h3>Send Us Your Jewelry</h3>
                <p>
                  If you like the quote you’ll receive a pre-paid shipping label including
                  insurance. Face-to-face collection can also be arranged.
                </p>
              </div>
              <div className={'hidden flex-shrink self-center lg:block'}>
                <IconArrowRight className={'text-accent'} />
              </div>
              <div className={'flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1'}>
                <StaticImage src={'../images/homepage/inspection.png'} alt={'Inspection'} />
                <h3>Full Jewelry Inspection</h3>
                <p>
                  Once we receive your jewelry our qualified experts will inspect its condition and
                  authenticity.
                </p>
              </div>
              <div className={'hidden flex-shrink self-center md:block'}>
                <IconArrowRight className={'text-accent'} />
              </div>
              <div className={'flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1'}>
                <StaticImage src={'../images/homepage/payment.png'} alt={'Payment'} />
                <h3>Receive Payment</h3>
                <p>Once finalized you will quickly receive payment via check or wire payment.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20" id="Additional Quote Forms">
        <h2 className="py-10 text-center text-4xl uppercase">OTHER QUOTE FORMS</h2>

        <div className="grid py-20 text-2xl md:grid-cols-3 xl:text-3xl">
          <div>
            <button className="formLgBtn">JEWELRY QUOTE FORM</button>
          </div>
          <div>
            <button className="formLgBtn">DIAMOND QUOTE FORM</button>
          </div>
          <div>
            <button className="formLgBtn">SILVER QUOTE FORM</button>
          </div>
        </div>
      </section>

      <div className={'container mx-auto mt-16 flex flex-wrap gap-y-16 px-4 md:p-0'}>
        <section className={'w-full'}>
          <h2 className={'text-center text-4xl uppercase'}>Still have Questions?</h2>
        </section>
        <section className={'w-full'}>
          <p className={'mx-auto w-full text-center text-lg lg:w-1/2 lg:text-xl 2xl:w-2/5'}>
            Our highly trained specialists, coupled with our international sales reach, allow us to
            offer the very best prices for your jewelry.
          </p>
        </section>

        <section className={'flex w-full flex-wrap gap-16 lg:flex-nowrap'}>
          <div
            className={
              'align-center flex h-full w-full flex-col justify-center py-24 text-center lg:w-1/3'
            }
          >
            <div className={'mb-8 text-5xl text-accent'}>
              <IconPlace />
            </div>
            <div className={'text-3xl uppercase'}>Visit Us</div>
            <div className={'mt-16 text-link'}>
              9595 Harding Avenue, Bal Harbour Florida 33154
            </div>
          </div>
          <div
            className={
              'align-center flex w-full flex-col justify-center border-l border-r border-accent text-center lg:w-1/3'
            }
          >
            <div className={'mb-8 text-5xl text-accent'}>
              <IconPhone />
            </div>
            <div className={'text-3xl uppercase'}>Call Us</div>
            <div className={'mt-16 text-accent'}><a href={"tel:+13057706955"}>tel: 305 770 6955</a></div>
          </div>
          <div className={'align-center flex w-full flex-col justify-center text-center lg:w-1/3'}>
            <div className={'mb-8 text-5xl text-accent'}>
              <IconMail2 />
            </div>
            <div className={'text-3xl uppercase'}>Email Us</div>
            <div className={'mt-16 text-accent'}><a href={"mailto:sell@grayandsons.com"}>sell@grayandsons.com</a></div>
          </div>
        </section>
      </div>

      <section className={'mt-16'}>
        <Map />
      </section>
    </Layout>
  );
};

export default CheckoutThankYou;
